import { get } from 'lodash';

const PRICE_BAR_BASE_WIDTH = 50;
export function getPriceBarSettings(trims, maxWidth = 90) {
  const variableWidth = maxWidth - PRICE_BAR_BASE_WIDTH;
  const trimPrices = trims.map(trim => get(trim, 'suggestedPrice', 0));
  let minPrice = Math.min(...trimPrices);
  let maxPrice = Math.max(...trimPrices);

  // When there is only 1 price
  if (minPrice === maxPrice) {
    minPrice *= 0.5;
    maxPrice *= 1.5;
  }

  return { basePrice: minPrice, percentValue: (maxPrice - minPrice) / variableWidth };
}

export function getPriceBarWidth({ priceBarSettings: { basePrice, percentValue }, suggestedPrice }) {
  return PRICE_BAR_BASE_WIDTH + (suggestedPrice - basePrice) / percentValue;
}
