import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleModel, buildSubmodelsPath } from 'client/data/models/vehicle-v2';
import { getSubmodelName } from 'site-modules/shared/utils/core-page/page-helpers';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';

const BODY_TYPE_SUBMODELS = ['sedan', 'coupe', 'suv', 'minivan'];
const HYBRID_SUBMODELS = ['hybrid', 'plug-in-hybrid'];

export const selectSubmodel = target => {
  const path = window.location.pathname.split('/').filter(segment => segment.length > 0);
  const yearSubmodel = target.id.split('|');
  const newLocation = getCoreUrl({
    makeSlug: path[0],
    modelSlug: path[1],
    year: yearSubmodel[0],
    submodelSlug: yearSubmodel[1],
  });
  const url = `${window.location.origin}${newLocation}${window.location.search}`;
  window.location.assign(url);
};

function SubmodelDropdownUI({ submodels, value, params, descriptionId }) {
  if (get(submodels, 'length', 0) < 2) {
    return null;
  }

  const useFuelTypeLabels =
    submodels.some(({ slug }) => HYBRID_SUBMODELS.includes(slug)) &&
    submodels.filter(({ slug }) => BODY_TYPE_SUBMODELS.includes(slug)).length === 1;

  const submodelsArray = submodels.map(({ slug }) => {
    const submodelValue = getSubmodelName(slug, submodels);

    return {
      value: submodelValue,
      id: `${params.year}|${slug}`,
      label: useFuelTypeLabels && BODY_TYPE_SUBMODELS.includes(slug) ? 'Gas' : submodelValue,
    };
  });

  return (
    <StyledSelect
      value={value}
      onChange={selectSubmodel}
      name="select-submodel"
      valueKey="id"
      labelKey="label"
      labelText="Select a type"
      wrapperClassName="label-inside mr-1 mb-1_5 w-100"
      labelClassName="small mb-0_5"
      overrideClasses="submodel-select d-block w-100 px-1 pt-1_75 pb-0_5 size-16 bg-white text-black rounded-8"
      options={submodelsArray}
      data-tracking-id="type_select"
      data-tracking-parent="edm-entry-model-summary-submodel"
      aria-describedby={descriptionId}
    />
  );
}

SubmodelDropdownUI.propTypes = {
  params: CorePageParams.isRequired,
  submodels: PropTypes.arrayOf(
    PropTypes.shape({
      niceId: PropTypes.string,
      identifier: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  descriptionId: PropTypes.string,
};

SubmodelDropdownUI.defaultProps = {
  submodels: null,
  value: '',
  descriptionId: null,
};

export const SubmodelDropdown = connectToModel(SubmodelDropdownUI, {
  submodels: bindToPath(({ params }) => params && buildSubmodelsPath(params), VehicleModel),
});
