import React from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleModel, buildLatestVehiclesPath, VehicleEntities } from 'client/data/models/vehicle-v2';
import { isPreProd, isNew } from 'site-modules/shared/utils/publication-states';
import { getNoSubmodelCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';

export const selectYear = target => {
  const path = window.location.pathname.split('/').filter(segment => segment.length > 0);
  const newLocation = getNoSubmodelCoreUrl({
    makeSlug: path[0],
    modelSlug: path[1],
    year: target.year.split(' - ')[0],
  });
  const url = `${window.location.origin}${newLocation}${window.location.search}`;
  window.location.assign(url);
};

function YearDropdownUI({ newModelYears, value, descriptionId }) {
  if (!newModelYears) {
    return null;
  }

  const newYears = [];

  newModelYears.forEach(year => {
    if (isPreProd(year.pubStates)) {
      newYears.push({ year: `${year.year} - Upcoming`, id: `${year.modelYearId}` });
    } else if (isNew(year.pubStates)) {
      newYears.push({ year: `${year.year} - New`, id: `${year.modelYearId}` });
    }
  });

  return (
    <StyledSelect
      value={value}
      onChange={selectYear}
      name="select-year"
      valueKey="id"
      labelKey="year"
      labelText="Select year"
      wrapperClassName="label-inside mr-1 mb-1_5 w-100"
      labelClassName="small mb-0_5"
      overrideClasses="year-select d-block w-100 px-1 pt-1_75 pb-0_5 size-16 bg-white text-black rounded-8"
      options={newYears}
      data-tracking-id="year_select"
      aria-describedby={descriptionId}
    />
  );
}

YearDropdownUI.propTypes = {
  newModelYears: VehicleEntities.LatestVehicleYears,
  value: PropTypes.string,
  descriptionId: PropTypes.string,
};

YearDropdownUI.defaultProps = {
  newModelYears: null,
  value: '',
  descriptionId: null,
};

export const YearDropdown = connectToModel(YearDropdownUI, {
  newModelYears: bindToPath(({ params }) => buildLatestVehiclesPath(params), VehicleModel),
});
