import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, flow } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  VehicleModel,
  buildModelFamilyIdsModelYearsPath,
  buildMmyModelFamilyIdsPath,
  buildSubmodelsPath,
} from 'client/data/models/vehicle-v2';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';

export const selectModelFamily = target => {
  const yearMakeModel = target.id.split('|');
  const newLocation = getCoreUrl({ makeSlug: yearMakeModel[1], modelSlug: yearMakeModel[2], year: yearMakeModel[0] });
  const url = `${window.location.origin}${newLocation}${window.location.search}`;
  window.location.assign(url);
};

function ModelFamilyDropdownUI({ modelYears, hasSubmodels, value, descriptionId }) {
  if (hasSubmodels || get(modelYears, 'length', 0) < 2) {
    return null;
  }

  const modelFamilyArray = modelYears.map(
    ({ modelName, modelSlug, makeSlug, year, engineType: currentEngineType }) => ({
      value: modelSlug,
      id: `${year}|${makeSlug}|${modelSlug}`,
      label:
        currentEngineType && modelYears.filter(({ engineType }) => engineType === currentEngineType).length === 1
          ? currentEngineType
          : modelName,
    })
  );

  return (
    <StyledSelect
      value={value}
      onChange={selectModelFamily}
      name="select-model-family"
      labelText="Select a type"
      wrapperClassName="label-inside mr-1 mb-1_5 w-100"
      labelClassName="small mb-0_5"
      overrideClasses="model-family-select d-block w-100 px-1 pt-1_75 pb-0_5 size-16 bg-white text-black rounded-8"
      options={modelFamilyArray}
      data-tracking-id="type_select"
      data-tracking-parent="edm-entry-model-summary-submodel"
      aria-describedby={descriptionId}
    />
  );
}

ModelFamilyDropdownUI.propTypes = {
  modelYears: PropTypes.arrayOf(
    PropTypes.shape({
      makeSlug: PropTypes.string,
      modelName: PropTypes.string,
      modelSlug: PropTypes.string,
      year: PropTypes.string,
      engineType: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  descriptionId: PropTypes.string,
  hasSubmodels: PropTypes.bool,
};

ModelFamilyDropdownUI.defaultProps = {
  modelYears: null,
  value: '',
  descriptionId: null,
  hasSubmodels: false,
};

export const ModelFamilyDropdown = flow(
  component =>
    connectToModel(component, {
      modelYears: bindToPath(
        ({ modelFamilyIds }) => !isEmpty(modelFamilyIds) && buildModelFamilyIdsModelYearsPath(modelFamilyIds),
        VehicleModel,
        (modelYears, { params }) => modelYears.filter(({ year }) => year === params.year)
      ),
    }),
  component =>
    connectToModel(component, {
      modelFamilyIds: bindToPath(({ params }) => !params.isTruck && buildMmyModelFamilyIdsPath(params), VehicleModel),
      hasSubmodels: bindToPath(
        ({ params }) => buildSubmodelsPath(params),
        VehicleModel,
        submodels => submodels?.length > 1
      ),
    })
)(ModelFamilyDropdownUI);
